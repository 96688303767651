<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <label class="mb-0"> إضافة موظف جديد </label>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- FirstName -->
          <validation-provider
            #default="validationContext"
            name="FirstName"
            rules="required"
          >
            <b-form-group label=" الاسم" label-for="FirstName">
              <b-form-input
                id="first-name"
                v-model="userData.FirstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- lastName -->
          <validation-provider
            #default="validationContext"
            name="lastName"
            rules="required"
          >
            <b-form-group label="النسبة" label-for="lastName">
              <b-form-input
                id="lastName"
                v-model="userData.lastName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required"
          >
            <b-form-group label="اسم المستخدم" label-for="Username">
              <b-form-input
                id="Username"
                v-model="userData.Username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Department -->
          <validation-provider
            #default="validationContext"
            name="Contact"
            rules="required"
          >
            <b-form-group label="القسم" label-for="contact">
              <v-select
                v-model="userData.role"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-department"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider #default="validationContext" name="role" rules="required">
            <b-form-group
              label="الدور"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="password"
            rules="required|password"
          >
            <b-form-group
              label="كلمة المرور"
              label-for="كلمة المرور"
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password2"
                v-model="userData.password"
                class="form-control-merge"
                type="password"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />

              <!-- <b-input-group-append is-text>
                  <feather-icon
    
                            :icon="passwordToggleIcon"
    
                            class="cursor-pointer"
    
                            @click="togglePasswordVisibility"
    
                          /> 
                </b-input-group-append> -->
            </b-form-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="confirmPassword"
            vid="confirmPassword"
            rules="required|confirmed:password"
          >
            <b-form-group
              label="تأكيد كلمة المرور"
              label-for="تأكيد كلمة المرور"
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                v-model="userData.confirmPassword"
                class="form-control-merge"
                type="password"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />
            </b-form-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- Plan -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
// import countries from '@/@fake-db/data/other/countries'
import store from "@/store";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },

  directives: {
    Ripple,
  },

  setup(props, { emit }) {
    const blankUserData = {
      FirstName: "",
      Username: "",
      lastName: "",
      role: null,
      password: "",
      confirmPassword: "",

      role_id: 1,
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

   

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script> 

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>