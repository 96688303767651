import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getServType(){
        axios
          .get("/api/v1/beneficiaries_page/get_dropdown_data")
          .then((response) => {
         //console.log("DD")
            resolve(response);
          })
          .catch((error) => reject(error));
    },
    fetchUsers(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/information_manager/users", { params: queryParams })
          .then((response) => {
         
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/information_manager/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/user/register", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
